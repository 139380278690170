import React, { useState , useEffect} from 'react'
import styled from 'styled-components'
import Announcement from '../components/Announcement'
import Footer from '../components/Footer'
import Products from '../components/Products/Products'
import Newsletter from '../components/Newsletter/Newsletter'

const ProductList = () => {
    const location = window.location.pathname;
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [location]);

    const [choice, setChoice] = useState();
  return (
    <>
    <Container>
        <Announcement />
        <Title>Dresses</Title>
        <FilterContainer>
            <Filter>
                <FilterText> Filter Products:</FilterText>
                <Select
                value={choice}
                defaultValue={'default'}
                onChange={(e) => setChoice(e.target.value)}
                >
                    <Option  value={'default'} disabled> Color</Option>
                    <Option>White</Option>
                    <Option>Black</Option>
                    <Option>Red</Option>
                    <Option>Blue</Option>
                    <Option>Yellow</Option>
                    <Option>Green</Option>
                </Select>
                <Select
                value={choice}
                defaultValue={'default'}
                onChange={(e) => setChoice(e.target.value)}
                >
                    <Option value={'default'} disabled> Size</Option>
                    <Option>XS</Option>
                    <Option>S</Option>
                    <Option>M</Option>
                    <Option>L</Option>
                    <Option>XL</Option>
                </Select>
            </Filter>
            <Filter>
                <FilterText> Sort Products:</FilterText>
                <Select>
                    <Option> Newest</Option>
                    <Option>Price (asc)</Option>
                    <Option>Price (desc)</Option>
                </Select>
            </Filter>
        </FilterContainer>
        <Products />
        <Newsletter />
        <Footer />
    </Container>
    </>
  )
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: auto;
`;

const Title = styled.h1`
    margin: 20px;
`
const FilterContainer = styled.div`
    display:flex;
    justify-content: space-between;
`
const Filter = styled.div`
    margin: 20px;
`

const FilterText = styled.span`
    font-size: 20px;
    font-weight: 600;
    margin-right: 20px;
`

const Select = styled.select`
    margin: 0px 5px;
    padding: 5px;
`;
const Option = styled.option`
    padding: 3px;
`;
export default ProductList