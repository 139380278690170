import styled from 'styled-components'
import React from 'react'
import { popularProducts } from '../../data/data'
import Product from './Product'

const Products = () => {
  return (
    <Container>
        {popularProducts.map((item) => (
            <Product item={item} key={item.id} />
        ))}
    </Container>
  )
}

const Container = styled.div`
    display: flex;
    height: auto;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-evenly;
    padding: 20px;
`

export default Products