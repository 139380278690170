import Announcement from '../components/Announcement'
import React from 'react'
import Navbar from '../components/Navbar'
import Slider from '../components/Slider'
import Categories from '../components/Categories/Categories'
import SubCategories from '../components/Categories/SubCategories'
import Products from '../components/Products/Products'
import Newsletter from '../components/Newsletter/Newsletter'
import Footer from '../components/Footer'
import { useEffect } from 'react'

// import loginUser from '../db_requests/UserList'




const Home = ({cart}) => {
  const location = window.location.pathname;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  // const list = loginUser();
  // console.log(list);

  return (
      <>
        <Announcement />
        {/* <Navbar /> */}
        <Slider />
        <Categories />
        <Products />
        <Newsletter />
        <Footer />
      </>
  )
}

export default Home