import Home from './pages/Home'
import ProductList from './pages/ProductList';
import { BrowserRouter, Routes, Route, useParams } from 'react-router-dom';
import Product from './pages/Product';
import Register from './pages/Register';
import Login from './pages/Login';
import Cart from './pages/Cart';
import { useEffect, useState } from 'react';
import Navbar from './components/Navbar';


const initialItems = [];

function App() {

  const [items, setItems] = useState(initialItems);

  function handleAddItem(item) {
    const checker = items.find((e) => e[0].id === item[0].id)
    // console.log(checker);
    if(!checker){
      setItems([...items, item]);
    } 
  }

  function handleRemoveItem(index) {
    const copy = [...items];
    copy.splice(index, 1);
    setItems(copy);
  }
  function updateQuantity(id, quantity){
    const index = items.findIndex(e => e[0].id == id);
    const copy = [...items];
    // console.log(copy);
    copy[index][1] = quantity;
    // console.log(copy);
    setItems(copy);
  }
  
  return (
    <>
     <BrowserRouter onUpdate={() => window.scrollTo(0, 0)}>
        <Navbar cart={items} />
        <Routes>
            <Route index element={<Home cart={items} />} />
            <Route path="/Products" element={<ProductList />} />
            <Route path='/Products/:productId' element={<Product  items={items} add={handleAddItem}/>} />
            <Route path="/Register" element={<Register />} />
            <Route path="/Login" element={<Login />} />
            <Route path="/Cart" element={<Cart cart={items} remove={handleRemoveItem} update={updateQuantity}  />} />
        </Routes>
    </BrowserRouter>
    </>
  
  );
}

export default App;
