import styled from 'styled-components'
import React from 'react'
import Products from './Products'
import placeHolderImg from '../../assets/placeholder.png'
import { FavoriteBorderOutlined, SearchOutlined, ShoppingCartOutlined } from '@material-ui/icons'
import {Link, NavLink} from 'react-router-dom';
const Product = ({item}) => {
  return (
    <Container>
        <Circle />
        <Image src={item.img ? item.img : placeHolderImg } />
        <InfoContainer>
            <Icon>
                <ShoppingCartOutlined />
            </Icon>
            <Icon>
                <Link to={`/Products/${item.id}`}>
                    <SearchOutlined />
                </Link>
            </Icon>
            <Icon>
                 <FavoriteBorderOutlined />
            </Icon>
            

        </InfoContainer>
    </Container>
  )
}


const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5px;
    width: 20%;
    height: 350px;
    /* border: 2px  black ridge; */
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    padding: 10px;
    background-color: aliceblue;
    position: relative;
    @media screen  and (max-width: 780px){
        height: 300px;
        width: 90%;
    }
`

const Circle = styled.div`
    height: 80%;
    width: 80%;
    border-radius: 50%;
    position: absolute;
    background-color: #f16d6d;
`;

const Image = styled.img`
    height: 75%;
    width: 90%;
    object-fit: cover;
    /* background-color: transparent; */
    z-index: 2;
`;
const InfoContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width:100%;
    height:100%;
    position: absolute;
    top: 0;
    background: #0e09031d;
    z-index: 3;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    &:hover{
        opacity:1;
        cursor: pointer;
    }
`;
const Icon = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #fafafa;
    margin: 10px;
    transition: all 0.5s ease-in-out;
    @media screen  and (max-width: 780px){
        height: 30px;
        width: 30px;
        transform: scale(1.3);
    }
    &:hover{
        background-color: #e4532f;
        color: #fafafa;
        transform: scale(1.3);
    }

`;




export default Product