import React from 'react'
import styled from 'styled-components'
import bgRegister from '../assets/hero-images/reg-img.webp'

const Register = () => {
  return (
    <>
    <Container>
        <Wrapper>
          <Title>CREATE AN ACCOUNT</Title>
          <Form>         
            <InputContainer>
                <Input type={'text'} placeholder="First Name" />
                <Input type={'text'} placeholder="Last Name" />
                <Input type={'text'} placeholder="Username" />
                <Input type={'mail'} placeholder="Email" />

                <Input type={'password'} placeholder="Password" />
                <Input type={'password'} placeholder="Confirm password" />
            </InputContainer> 
            <AgreeContainer>
              <Checkbox type={'checkbox'} value='1' />
              <Agreement htmlFor='agreement'>
                By creating an account, I consent to the processing of my personal data in accordance with the <b>PRIVACY POLICY</b>.
              </Agreement>
            </AgreeContainer>


            <Submit>CREATE</Submit>

         
          </Form>
        </Wrapper>
    </Container>
    </>
  )
}


const Container = styled.div`
    display:flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    padding: 0;
    margin: 0;
`;

const Wrapper = styled.div`
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background: linear-gradient(rgba(250, 243, 243, 0.11), rgba(238, 54, 198, 0.493)), url(${bgRegister});
    padding: 0;
    margin: 0;
    position: relative;
    @media screen  and (max-width: 780px){
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }
`

    const Title = styled.h1`
      font-size: 32px;
      margin: 40px 0 0 40px;
      font-weight: 900;
      position: absolute;
      top: 5%;
      left: 0;
      @media screen  and (max-width: 780px){
        width: 80%;
        font-size: 26px;
        text-shadow: 0px 0px 2px #000000;
        color: #fffffff6;
        text-align: center;
      }
    `

    const Form = styled.form`
          display:flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          background-color: white;
          flex-wrap: wrap;
          width: 50%;
          padding: 5px;
          margin: 0;
          box-shadow: rgba(238, 54, 198, 0.25) 0px 50px 100px -20px, rgba(238, 54, 198, 0.3) 0px 30px 60px -30px, rgba(238, 54, 198, 0.35) 0px -2px 6px 0px inset;
    
          @media screen  and (max-width: 780px){
            width: 90%;
            margin-top: 20%;
          }
    `

    const InputContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
    width: 100%;
    `
    const Input = styled.input`
        outline: none;
        border: none;
        border-bottom: 2px gray solid;
       
        font-size: 16px;
        margin: 20px 10px 0px 0px;
        min-width: 40%;
        padding: 2px 10px;

        &:hover{
          outline: none;
          border: none;
          border-bottom: 2px black solid;
        }
        @media screen  and (max-width: 780px){
             padding: 2px 5px;
          }
    `

    

    const AgreeContainer = styled.div`
      display: flex;
      width: 100%;
      height: auto;
      /* flex-direction: column; */
      justify-content: space-evenly;
      align-items: center;
      margin: 10px;
      @media screen  and (max-width: 780px){
        flex-direction: column;
        padding: 10px;
      }
    `
         const Checkbox = styled.input`
           @media screen  and (max-width: 780px){
             margin: 10px 0;
           }

         `
         const Agreement = styled.label`
            font-size: 13px;
            font-weight: 300;
            margin: 20px 0;
            @media screen  and (max-width: 780px){
              margin: 0;
              text-align: left;
            }
         `

        const Submit = styled.button`
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 12px 40px;
            margin: 10px;
            background-color: #fafafa;
            color: teal;
            outline: none;
            border: 1px teal solid;
            letter-spacing: 1px;
            cursor: pointer;
            &:hover{
                background-color: teal;
                color: #fafafa;
                border: 1px #fafafa solid;
            }
        `


export default Register