import React from 'react';
import styled from 'styled-components';
import { ArrowBackIosOutlined as ArrowLeft, ArrowForwardIosOutlined as ArrowRight} from "@material-ui/icons";
import { useEffect, useState } from 'react';
import {sliderItems} from '../data/data';
import {Link, NavLink} from 'react-router-dom';

const Slider = () => {
    const [slideIndex, setSlideIndex] = useState(0); 
    const handleClick = (direction) => {
            direction === "left" ? setSlideIndex(slideIndex > 0 ? slideIndex -1 : 2) :  setSlideIndex(slideIndex < 2 ? slideIndex + 1 : 0)
    }
  return (
    <Container>
        <Arrow direct="left" onClick={() => handleClick('left')} >
            <ArrowLeft />
        </Arrow>
        <Arrow direct="right" onClick={() => handleClick('right')} >
            <ArrowRight />
        </Arrow>
        <Wrapper slideIndex={slideIndex}>
            {sliderItems.map((item) => (
                <Slide bg={item.bg} key={item.id} >
                     <ImgContainer>
                         <Image src={item.img} />
                     </ImgContainer>
                     <InfoContainer>
                             <Title>{item.title}</Title>
                             <Desc> {item.desc} </Desc>
                             <Button> <Link to="/Products">SHOP NOW</Link></Button>
                     </InfoContainer>
                 </Slide>

                ))
            }
         
        </Wrapper>

      

    </Container>
  )
}

export default Slider

const Container = styled.div`
    display: flex;
    width: 100%;
    height: 80vh;
    flex-grow: 1;
    position: relative;
    overflow: hidden;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
`

const Arrow = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    background-color: #ebe2e2;
    border-radius: 50%;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    left: ${props=> props.direct === "left" && "10px" };
    right: ${props=> props.direct === "right" && "10px" };
    cursor: pointer;
    opacity: 0.5;
    z-index: 2;
`;

const Wrapper = styled.div`
  
    display: flex;
    height: 100%;
    /* overflow-x: hidden; */
    /* background-color: #ffad88; */
    transform: translateX(${(props) => props.slideIndex * -100}vw);
    transition: transform 1s ease-in-out;
`;

const Slide = styled.div`
    display: flex;
    align-items: center;
    width: auto;
    height: 100%;
    padding-top: 10px;
    background-color: rgba(${props => props.bg});
    @media screen  and (max-width: 780px){
        flex-direction: column;
    }
`;

const ImgContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 50vw;   
    object-fit: contain;
    
    @media screen  and (max-width: 780px){
        width: 100vw;
        height: 50%;
    }
`;

        const Image = styled.img`
            height: 100%;
            width: 90%;
            object-fit: cover;
        `;

const InfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 50vw;
    padding: 50px 50px;
    @media screen  and (max-width: 780px){
        width: 100vw;
        height: 40%;
        padding: 20px 10px;
    }
`;
        const Title = styled.h1`
            font-size: 50px;
            @media screen  and (max-width: 780px){
                font-size: 32px;
                margin: 10px 0px;
            }
        `;

        const Desc = styled.p`
            margin: 50px 0px;
            font-size: 20px;
            font-weight: 500;
            letter-spacing: 3px;
            @media screen  and (max-width: 780px){
                font-size: 16px;
                margin: 5px 5px;
            }
        `;

        const Button = styled.button`
            padding: 10px 20px;
            font-size: 20px;
            /* background-color: #fafafa; */
            outline: none;
            background-color: transparent;
            border: 1px black solid;
            letter-spacing: 1px;
            font-weight: 700;
            cursor: pointer;

            &:hover{
                background-color: #030303;
                color: #fafafa;
            }
        `;
