import React from 'react';
import Navbar from '../components/Navbar';
import styled from 'styled-components';
import bgRegister from '../assets/hero-images/login-bg.jpg';

const Login = () => {
  return (
    <Container>
        {/* <Navbar /> */}
        <Wrapper>
          <Title>SIGN IN</Title>
          <Form>
            <InputContainer>
                <Input type={'text'} placeholder="Username" />
                <Input type={'password'} placeholder="Password" />
                
            </InputContainer> 
            <Submit>LOG IN</Submit>
            <Link>Did you forget your password ?</Link>
            <Link>Create a new account now !</Link>
          </Form>
          
         
        </Wrapper>
    </Container>
  )
}


const Container = styled.div`
    display:flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    padding: 0;
    margin: 0;
`;

const Wrapper = styled.div`
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background: linear-gradient(rgba(250, 243, 243, 0.11), rgba(238, 54, 198, 0.267)), url(${bgRegister}) no-repeat center center fixed;
    /* background-attachment: fixed;
    background-repeat: no-repeat;
    object-fit: cover; */
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
    padding: 0;
    margin: 0;
    position: relative;
`
      const Form = styled.form`
      display:flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: white;
      flex-wrap: wrap;
      width: 50%;
      padding: 5px;
      margin: 0;
      box-shadow: rgb(27, 47, 233, 0.25) 0px 50px 100px -20px, rgb(27, 47, 233, 0.3) 0px 30px 60px -30px, rgb(27, 47, 233, 0.35) 0px -2px 6px 0px inset;
      /* box-shadow: rgb(27, 47, 233) 0px 3px 8px, rgb(27, 47, 233) 0px 3px 8px, rgb(27, 47, 233) 0px 3px 8px; */

      
      @media screen  and (max-width: 780px){
            width: 70%;
        }
      `
      const Title = styled.h1`
          font-size: 32px;
          margin: 40px 0 0 40px;
          font-weight: 900;
          position: absolute;
          color: #fafafa;
          top: 5%;
          left: 5%;
      `
        const InputContainer = styled.div`
             display: flex;
             align-items: center;
             justify-content: space-evenly;
             flex-wrap: wrap;
             width: 100%;
             padding: 10px;
             margin: 5px;
        `

        const Input = styled.input`
        outline: none;
        border: none;
        border-bottom: 2px gray solid;
        font-size: 16px;
        margin: 20px 10px 0px 0px;
        min-width: 40%;
        padding: 5px 10px;

        &:hover{
          outline: none;
          border: none;
          border-bottom: 2px black solid;
        }
        `

        const Link = styled.a`
          display: flex;
          justify-content: center;
          width: 50%;
          padding: 5px 20px;
          margin: 5px;
          cursor: pointer;

          &:hover{
            text-decoration: underline;
          }

          @media screen  and (max-width: 780px){
             width: 80%;
             text-align: center;
          }
          
        `
          const Submit = styled.button`
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 12px 40px;
          margin: 10px;
          background-color: #fafafa;
          color: teal;
          outline: none;
          border: 1px teal solid;
          letter-spacing: 1px;
          cursor: pointer;
          &:hover{
              background-color: teal;
              color: #fafafa;
              border: 1px #fafafa solid;
          }
      `
export default Login