import React from 'react'
import { Send } from '@material-ui/icons'
import styled from 'styled-components'

const Newsletter = () => {
  return (
    <Container>
        <Title>NewsLetter</Title>
        <Description> Get timely updates from your favorite products.</Description>
        <InputContainer>
            <Input placeholder='Your email' />
            <Button>
                <Send />
            </Button>
        </InputContainer>
    </Container>
  )
}


const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 45vh;
    background-color: #fcf5f5;
    @media screen  and (max-width: 780px){
        height: 50vh;
    }
`;

const Title = styled.h1`
    font-size: 70px;
    margin-bottom: 20px;
    @media screen  and (max-width: 780px){
        font-size: 50px;
    }
`;

const Description = styled.p`
    font-size: 24px;
    font-weight: 300;
    margin-bottom: 20px;
    @media screen  and (max-width: 780px){
        font-size: 20px;
        text-align: center;
    }
`;


const Input = styled.input`
    border: none;
    flex: 8;
    padding-left: 15px;

    &:focus{
        border: none;
        outline: none;
    }
`;
const Button = styled.button`
    border: none;
    flex: 1;
    background-color: teal;
    color: white;
    transition: all 0.3s;
    /* &:hover{
        background-color: orange;
    } */
`;
const InputContainer = styled.div`
    display: flex;
    justify-content: space-between;
    width: 40%;
    height: 40px;
    background-color: white;
    border: 1px lightgray solid;
    &:hover ${Button}{
        background-color: orange;
    }
    @media screen  and (max-width: 780px){
        width: 80%;
    }
`;

export default Newsletter