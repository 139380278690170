
import React, { useState, useEffect }  from 'react'
import styled from 'styled-components'
import Announcement from '../components/Announcement';
import Footer from '../components/Footer';
import { Add, Remove } from '@material-ui/icons'
import { popularProducts } from '../data/data'
import { useParams } from 'react-router-dom';


const Product = ({ add, items }) => {
    console.log(items);
  // product id - custom path
  
  const { productId } = useParams();
  const location = window.location.pathname;
  useEffect(() => {
      window.scrollTo(0, 0);
  }, [location]);

  const [currentAmount, setAmount] = useState(1);
  const [currentSize, setSize] = useState('S');
  const [coloring, setColoring] = useState('black');
  const [hidden, notHidden] = useState('hidden');
    
  const found = popularProducts.find(e => e.id === productId);
  
  return (
    <Container>
        <Announcement />
            <Wrapper>
                <ImgContainer>
                    <Image src={found.img} />
                </ImgContainer>
                <InfoContainer id='added'>
                    <Title>{found.name}</Title>
                    <Description>
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Id quia accusantium reiciendis? Incidunt nihil alias ex nemo esse dicta aspernatur veritatis cumque qui veniam ea, iste quam? Molestias voluptatem deleniti, natus explicabo libero rem. Molestias, iusto veritatis. Voluptate possimus harum distinctio!
                    </Description>
                    <Price>$ 20</Price>
                    <FilterContainer>
                        <Filter>
                            <FilterTitle>Color</FilterTitle>
                            <FilterColor  onClick={() => setColoring('black')} color="black" />
                            <FilterColor  onClick={() =>setColoring('lightblue')} color="lightblue" />
                            <FilterColor  onClick={() =>setColoring('pink')} color="pink" />
                            <FilterColor  onClick={() =>setColoring('gray')} color="gray" />
                        </Filter>
                        <Filter>
                            <FilterTitle >Size</FilterTitle>
                            <FilterSize onChange={(e) => setSize(e.target.value)} id="sizer">
                                <FilterSizeOption value={'XS'}>XS </FilterSizeOption>
                                <FilterSizeOption value={'S'}>S</FilterSizeOption>
                                <FilterSizeOption value={'M'}>M</FilterSizeOption>
                                <FilterSizeOption value={'L'}>L</FilterSizeOption>
                                <FilterSizeOption value={'XL'}>XL</FilterSizeOption>
                            </FilterSize>
                        </Filter>
                    </FilterContainer>
                    <AddContainer>
                        <AmountContainer>
                            <Remove onClick={() => currentAmount > 1 ? setAmount(currentAmount - 1) : 1} />
                                <Amount>&nbsp;{currentAmount}&nbsp;</Amount>
                            <Add onClick={() => currentAmount < 10 ? setAmount(currentAmount + 1) : 1} />
                        </AmountContainer>
                    </AddContainer>

                    <Alert className={hidden}>added to cart !</Alert>
                    <Button  onClick={ () =>{
                         add([found, currentAmount, currentSize, coloring]);
                          notHidden('animate__fadeInDownBig');}
                        }>ADD TO CART</Button>
                    
                </InfoContainer>
            </Wrapper>
        <Footer />
    </Container>
  )
}

const Container = styled.div`
    display:flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    padding: 0;
    margin: 0;
`;

const Wrapper = styled.div`
    display: flex;
    max-width: 100%;
    padding: 50px;
    @media screen  and (max-width: 780px){
            flex-direction: column;
            padding: 20px;
    }
`

const Alert = styled.p`
    display: flex;
    /* opacity: 0; */
    justify-content: center;
    align-items: center;
    align-self: center;
    padding:1% 2%;
    width: auto;
    color: #fafafa;
    background-color: red;
    border-radius: 50px;
    font-weight: 600;
    margin: 10px;
    margin-bottom: 20px;
`

const ImgContainer = styled.div`
   flex:1;
   box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
   color: #f8a942f6;
`

    const Image = styled.img`
        object-fit: contain;
        width: 100%;
        height: 70vh;
        padding: 10px;
        
    `

const InfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 0 50px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    @media screen  and (max-width: 780px){
            margin: 5px 0;
            padding: 0 10px;
    }
`

    const Title = styled.h1`
        font-weight: 200;
        
        padding: 10px 20px;
    `

    const Description = styled.p`
        padding: 10px 20px;
        margin: 20px 0;

    `

    const Price = styled.h3`
        font-weight: 100;
        font-size: 40px;
        padding: 10px 20px;
    `
    const FilterContainer = styled.div`
        display: flex;
        justify-content: space-around;
        margin: 30px 20px;
    `
    const Filter = styled.div`
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: 5px 5px;
    `
    const FilterTitle = styled.h2`
        font-size: 30px;
        font-weight: 200;
        margin-bottom: 20px;
    `
    const FilterColor = styled.input`
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background-color: ${props => props.color};
        margin: 10px 10px;
        cursor: pointer;
        border: ${props => props.color} 2px solid;
        
        &:focus{
            background-color: ${props => props.color};
            color: ${props => props.color};
            padding: 2px;
            box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.2),  0 0 2px 5px  ${props => props.color};
            border: none;
            outline: none;
        }

    `
    const FilterSize = styled.select`
        padding: 5px;
        font-size: 20px;
    `
    const FilterSizeOption = styled.option`
        font-size: 20px;
    `
    
    const AddContainer = styled.div`

    `;

    const AmountContainer = styled.div`
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        margin: 30px 0;

        svg{
            height: 30px;
            width: 30px;
            background-color: gray;
            color: white;
            border-radius: 50%;
            cursor: pointer;
            

            &:active{
                background-color: white;
                color: gray;
            }
        }
    `;

    const Amount = styled.span`
        margin: 0 10px;
        font-size: 20px;
        font-weight: 700;
        border: 1px teal solid;
        border-radius: 30%;
        padding: 20px;
    `;

    const Button = styled.button`
        display: flex;
        justify-content: center;
        align-items: center;
        align-self: center;
        outline: none;
        border: 2px teal solid;
        padding: 10px 20px;
        margin-bottom: 15px;
        background-color: #fafafa;
        color:teal;
        font-weight: 700;
        cursor: pointer;

        &:hover{
            background-color: teal;
            color:#fafafa;
        }
        @media screen  and (max-width: 780px){
            flex-direction: column;
            margin-bottom: 20px;
        }
    `
export default Product