import React from 'react'
import Announcement from '../components/Announcement';
import styled from 'styled-components'
import Footer from '../components/Footer';

import CartProduct from '../components/CartProduct/CartProduct';
import { useEffect, useState } from 'react';




const Cart = ({cart, remove, update}) => {

  // console.log(cart);

  function getTotal(array){
    let total = 0;
    array.forEach((e) => {
        let price = parseInt(e[0].price);
        let qty = e[1];
        total += (price * qty);
    })
    return total;
  }

  function getFullTotal(param){
    let fullPrice = param + (Math.floor((param *0.2) * 100) / 100);
    return fullPrice;
  }


  const [subTotal, setSubTotal] = useState(getTotal(cart))
  const [TotalPrice, setTotalPrice] = useState(getFullTotal(subTotal))

  useEffect( () => {
    setSubTotal(getTotal(cart));
    setTotalPrice(getFullTotal(subTotal));
  })

  

  return (
    <Container>
    {/* <Navbar /> */}
    <Announcement />
    <Wrapper>
      <Title> Your BAG </Title>
      <Top>
        <TopButton>Continue Shopping</TopButton>
        <TopTexts>
          <TopText>ShoppingBag({cart.length})</TopText>
          <TopText>Your Wishlist(0)</TopText>
        </TopTexts>
        <TopButton type='filled'>Checkout now</TopButton>
      </Top>
      <Bottom>
          <Info>
            {cart.map((e, index) => (
                  <>
                   <CartProduct 
                   update={update}
                   key={index.toString()}
                   imgSrc={e[0].img} 
                   name={e[0].name}
                   id={e[0].id}
                   size={e[2]}
                   color={e[3]}
                   quantity={e[1]}
                   price={e[0].price}
                   remove={remove}
                   index={index}
                   />   
                   <Hr key={index+1} /> 
                    </>
              )
            )}
     

          </Info>
          <Summary>
                <SummaryTitle>ORDER SUMMARY</SummaryTitle>
                <SummaryItem>
                  <SummaryItemText>SubTotal</SummaryItemText>
                  <SummaryItemPrice>$ {subTotal}</SummaryItemPrice>
                </SummaryItem>
                <SummaryItem>
                  <SummaryItemText>Estimated Shipping</SummaryItemText>
                  <SummaryItemPrice>$ 40</SummaryItemPrice>
                </SummaryItem>
                <SummaryItem>
                  <SummaryItemText>Shipping Discount</SummaryItemText>
                  <SummaryItemPrice>$ 30</SummaryItemPrice>
                </SummaryItem>
                <SummaryItem>
                  <SummaryItemText >Taxes</SummaryItemText>
                  <SummaryItemPrice>20%</SummaryItemPrice>
                </SummaryItem>
                <SummaryItem type="total">
                  <SummaryItemText >Total</SummaryItemText>
                  <SummaryItemPrice>$ {TotalPrice + 10}</SummaryItemPrice>
                </SummaryItem>
                
                <Button>CHECKOUT NOW</Button>
          </Summary>
          
      </Bottom>
    </Wrapper>
    <Footer />
</Container>
  )
}


const Container = styled.div`
    display:flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    padding: 0;
    margin: 0;
`;

const Wrapper = styled.div`
    display:flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    position: relative;
`

    const Title = styled.h1`
      font-size: 32px;
      text-align: center;
      margin: 5px;
      font-weight: 300;
    `

    const Top = styled.div`
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 20px;
      border-bottom: 1px teal solid;
      margin-bottom: 10px;
      box-shadow: #00808022 0px 1px 2px 0px, #00808029 0px 2px 6px 2px;
      @media screen and (max-width: 780px){
          flex-direction: column;
      }
    
    `
        const TopButton = styled.button`
          margin: 10px;
          padding: 10px;
          font-weight: 600;
          cursor: pointer;
          border: ${(props) => props.type === "filled" && "none"};
          background-color: ${(props) => props.type === "filled" ? "black" : "transparent"};
          color: ${(props) => props.type === "filled" && "white"};
        `
        const TopTexts = styled.span`
          display: flex;
          align-items: center;
          justify-content: center;
          text-decoration: underline;
          cursor: pointer;
          margin: 10px;
        `
            const TopText = styled.div`
                margin: 5px;
            `
      
    const Bottom = styled.div`
      display: flex;
      justify-content: space-between;
      width: 100%;
      @media screen and (max-width: 780px){
          flex-direction: column;
      }
    `

        const Info = styled.div`
        flex: 3;
        `
             
           
              const Hr = styled.hr`
              margin: 20px;
              font-weight: 1000;
              background-color: #fafafa;
              border: none;
              box-shadow: #008080f4 0px 1px 2px 0px, #0080805a 0px 2px 6px 2px;
              `
        const Summary = styled.div`
              flex: 1;
              border: 0.5px solid lightgray;
              border-radius: 10px;
              padding: 20px;
              height: 50vh;
        `

          const SummaryTitle = styled.h1`
            font-weight: 200;
          `
          const SummaryItem = styled.div`
            margin: 30px 0;
            display: flex;
            justify-content: space-between;
            font-weight: ${(props) => props.type === 'total' ? 600 : 300} ;
            font-size: ${(props) => props.type === 'total' ? '22px' : '16px'} ;           
          `
          const SummaryItemText = styled.span`
            
          `
            const SummaryItemPrice = styled.span`
          
            `

          const Button = styled.button`
          margin-top: 40px;
          width: 100%;
          padding: 10px;
          background-color: black;
          color: #fafafa;
          font-weight: 600;
          font-size: 22px;
          cursor: pointer;
          border: none;
            outline: none;

          &:hover{
            background-color: teal;
            color: #fafafa;
            border: none;
            outline: none;
          }
          `

          
        

      
    

export default Cart