import React from 'react';
import styled from 'styled-components';
import {Link, NavLink} from 'react-router-dom';
const CategoryItem = ({item}) => {
  return (
    <Container key={item.id} >
            <Image src={item.img} />
            <Info>
                <Title>{item.title}</Title>
                <Button><Link to="/Products">SHOP NOW</Link></Button>
            </Info>
    </Container>
  )
}

const Container = styled.div`
    flex: 1;
    margin: 3px;
    height: 70vh;
    position: relative;
    @media screen  and (max-width: 780px){
                display: flex;
                justify-content: center;
                align-items: center;
                height: 50vh;
    }
`;

const Image = styled.img`
    height: 100%;
    width: 100%;
    object-fit: cover;
    @media screen  and (max-width: 780px){
        height: 300px;
        width: 100%;
    }
`

const Info = styled.div`
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

`
    const Title = styled.h1`
        color: #000000d3;
        background-color: #f0f8ff94;
        padding: 1%;
        box-shadow: 0 0 100px rgba(55, 175, 255, 0.267);
        position: absolute;
        top: 40%;
        @media screen  and (max-width: 780px){
            top: 20%;
        }
                /* margin-bottom: 10px; */
    `

    const Button = styled.button`
        margin-top: 30px ;
        border: none;
        padding: 15px 30px;
        background-color: white;
        color: gray;
        cursor: pointer;
        font-weight: 600;
        font-size: 1.2em;
        position: relative;
        overflow: hidden;

        &:after{
            content:'';
            opacity: 1;
            right: 0;
            bottom: 0;
            position: absolute;
            width: 100%;
            border-bottom: 2px black solid;
            /* background-color: black; */
            color: black;
            padding: 0;
            transform: translateX(-100%);
        }
        &:before{
            content:'';
            opacity: 1;
            right: 0;
            top: 0;
            position: absolute;
            width: 100%;
            border-bottom: 2px black solid;
            /* background-color: black; */
            color: black;
            padding: 0;
            transform: translateX(100%);
        }
        &:hover{
            animation: pulse 2s forwards;
            &:after{
                transform: translateX(0);
                transition: transform 0.8s ease-in-out;
            }
            &:before{
                transform: translateX(0);
                transition: transform 0.8s ease-in-out;
            }
        }

    `
export default CategoryItem