import React from 'react'
import styled from 'styled-components'
import { Add, Remove, DeleteOutline } from '@material-ui/icons';
import { useState } from 'react';
import { useEffect } from 'react';

const CartProduct = ({imgSrc, name, id, color, size, quantity, price, remove, index, update}) => {

  const [currentAmount, setAmount] = useState(quantity);
  useEffect(
    () => {
      update(id, currentAmount)
    }, [currentAmount]
  )
  return (
    <Product>
    <ProductDetail>
      <Image src={imgSrc} />
      <Details>
        <ProductName><b>Product:</b> {name}</ProductName>
        <ProductId><b>ID:</b> {id}</ProductId>
        <ProductColor><b>Color:</b>  <Color color={color} /></ProductColor> 
        <ProductSize><b>Size:</b> {size}</ProductSize>
      </Details>
    </ProductDetail>
    <PriceDetail>
        <ProductAmountContainer>
          <Remove onClick={() => currentAmount > 1 ? setAmount(currentAmount - 1) : 1} />
          <ProductAmount>{currentAmount}</ProductAmount>
          <Add onClick={() => currentAmount < 10 ? setAmount(currentAmount + 1) : 10} />
        </ProductAmountContainer>
        <ProductPrice>$ {price}</ProductPrice>
        <RemoveItem onClick={() =>{remove(index)}}>
          {/* onClick={() =>{remove()} */}
            <DeleteOutline />
        </RemoveItem>
    </PriceDetail>
    
   
  </Product>
  )
}


const Product = styled.div`
display: flex;
justify-content: space-between;
position: relative;
/* width: 100%; */
`;
  const ProductDetail = styled.div`
    flex: 2;
    display: flex;
  `;
  const Image = styled.img`
    height: 200px;
    width: 200px;
    object-fit: cover;
  `;
  const Details = styled.div`
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  `;
  const ProductName = styled.div``;
  const ProductId = styled.div``;
  const ProductColor = styled.div`
    display: flex;
    align-items: center;
  `
  const Color = styled.div`
    margin-left: 10px;
    width: 25px;
    height: 25px;
    background-color: ${(props) => props.color};
    border: 0.5px ${(props) => props.color === 'fafafa' ? 'black' : 'transparent'} solid;
    border-radius: 50%;
  `;
  const ProductSize = styled.div``;

  const PriceDetail = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `;

      const ProductAmountContainer = styled.div`
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        font-weight: 900;
      `
      const ProductAmount = styled.div`
        margin: 10px;
        font-size: 24px;
        font-weight: 400;
      `
      const ProductPrice = styled.div`
        margin: 10px;
        font-size: 30px;
        font-weight: 300;
      `
      const RemoveItem = styled.button`
         margin: 10px;
         display: flex;
         justify-content: center;
         align-items: center;
         background-color: transparent;
         color: red;
         padding: 1%;
         outline: none;
         border: none;
         box-shadow: 0px 0px 2px black;
         cursor: pointer;
         &:hover{
          background-color: black;
          box-shadow: 0px 0px 2px 2px red;
         }
      `

export default CartProduct