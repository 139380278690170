import React from 'react'
import { Facebook, Instagram, MailOutline, Phone, Pinterest, Room, Send, Twitter } from '@material-ui/icons'
import styled from 'styled-components'
import paymentsImg from '../assets/payments.png'
import {Link, NavLink} from 'react-router-dom';
const Footer = () => {
  return (
    <Container>
        <Left>
            <Logo>SHOPIKAMI. &#169;</Logo>
            <Desc>
                There are many variations of passages of Lorem Ipsum available, 
                but the majority have suffered alteration in some form, by injected 
                humour, or randomised words which don't look even slightly believable.
            </Desc>
            <SocialContainer>
                <SocialIcon color="3B5999">
                    <Facebook />
                </SocialIcon>
                <SocialIcon color="E4405F">
                    <Twitter />
                </SocialIcon>
                <SocialIcon color="55ACEE">
                    <Instagram />
                </SocialIcon>
                <SocialIcon color="E60023">
                    <Pinterest />
                </SocialIcon>
                
            </SocialContainer>
        </Left>
        <Center>
            <Title>Useful Links</Title>
            <List>
                <ListItem>  
                    <Link to="/">
                        <LinkItem>Home </LinkItem> 
                    </Link>  
                </ListItem>
                <ListItem>  
                    <Link to="/Cart">
                        <LinkItem>Cart </LinkItem>
                    </Link>   
                </ListItem>
                <ListItem>  
                    <LinkItem>Man Fashion</LinkItem> 
                </ListItem>
                <ListItem>  
                    <LinkItem>Woman Fashion </LinkItem>  
                </ListItem>
                <ListItem>  
                    <LinkItem>Accessories</LinkItem> 
                </ListItem>
                <ListItem>  
                    <LinkItem>My Account </LinkItem>
                </ListItem>
                <ListItem>  
                    <LinkItem>Order Tracking</LinkItem>  
                </ListItem>
                <ListItem>  
                    <LinkItem>Accessories</LinkItem> 
                </ListItem>
                <ListItem>  
                    <LinkItem>Wishlist </LinkItem>   
                </ListItem>
                <ListItem>  
                    <Link to="/">
                        <LinkItem>Terms</LinkItem>
                    </Link>   
                </ListItem>
                
            </List>
        </Center>
        <Right>
            <Title>Contact</Title>
            <ContactItem> <Room /> 622 Dixie Path ,  South Tobinchester 98336 </ContactItem>
            <ContactItem> <Phone /> +1 234 56 78 </ContactItem>
            <ContactItem> <MailOutline />contact@shopikami.dev </ContactItem>
            <Payment src={'https://njengah.com/wp-content/uploads/2020/07/payment-icons-for-WordPress.png'}>

            </Payment>
        </Right>
    </Container>
  )
}

const Container = styled.div`
    display: flex;
    justify-content: space-around;   
    background-color: orange;
    width: 100%;
    @media screen  and (max-width: 780px){
        flex-direction: column;
    }
`;


    
const Left = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px;
    flex: 1;
`

    const Logo = styled.h1`
        text-align: center;
        color: #000000;
        font-weight: 700;
    `

    const Desc = styled.p`
        align-self: center;
        margin: 10px 0px;
        padding-left: 20px;
        width: 80%;
        text-justify: center;
        font-weight: 500;
    `

    const SocialContainer = styled.div`
        display: flex;
        justify-content: center;
        align-items: center;
    `

    const SocialIcon = styled.i`
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 5px 5px;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: #${props => props.color};
        color: #fafafa;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        &:hover{
            color: #${props=> props.color};
            background-color: #fafafa;
            
        }
    `

    
const Center = styled.div`
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    flex: 1;
    padding: 20px;
    @media screen  and (max-width: 780px){
            padding: 0;
    }
    
`

    const Title = styled.h2`
        margin-bottom: 10px;
        font-weight: 700;
        @media screen  and (max-width: 780px){
            margin-bottom: 10px;
        }
    `;

    const List = styled.ul`
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        list-style: none;
        margin: 0;
        padding: 20px 50px;
        box-sizing: border-box;
    `

    const ListItem = styled.li`
        display: flex;
        width: 40%;
        margin: 5px;
        text-decoration: underline;
        @media screen  and (max-width: 780px){
            width: 45%;
        }
    
    `;

    const LinkItem = styled.span`
        display: flex;
         margin-left: 40px;
        color: black;
        text-decoration: none;
        cursor: pointer;
        &:hover{
            color: #fafafa;
        }
        @media screen  and (max-width: 780px){
            margin-left: 0;
        }
    `
const Right = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    padding: 20px;
    @media screen  and (max-width: 780px){
        padding: 10px;
    }
`

    const ContactItem = styled.div`
        display: flex;
        align-items: center;
        width: 80%;
        margin-bottom: 20px;
        font-weight: 700;
        @media screen  and (max-width: 780px){
             margin: 10px;
             width: 100%;
        }
        svg{
          margin-right: 10px;
        }
    `;

    const Payment = styled.img`
    display: flex;
    width: 50%;
    background-color: orange;
    
    @media screen  and (max-width: 780px){
            width: 100%;
    }
    `
export default Footer