import summerImg from '../assets/hero-images/summer-sale.jpg';
import autumnImg from '../assets/hero-images/autumn-sale.webp';
import loungewearImg from '../assets/hero-images/loungewear-sale.jpg';


import shirtImg from '../assets/category-images/shirt-style.jpg';
import loungeSexyImg from '../assets/category-images/lounge-style.jpg';
import lightJacketImg from '../assets/category-images/lightjacket-style.jpg';
import BlueDress from '../assets/category-images/women-blue-dress.png';
import PurpleDress from '../assets/category-images/purple-dress.png';
import CasualJeans from '../assets/category-images/jean-casual.png';

import SakuraCutie from '../assets/category-images/casual-woman.webp';
// import AsianModel from '../assets/category-images/Asian-Model.jpg'
import AsianModel from '../assets/category-images/casual-asian.jpg'




import FlowerDress from '../assets/products/robe-fleurs.png';
import GreenDress from '../assets/products/green-dress.png';
import OrangeDress from '../assets/products/orange-dress.png';



export const sliderItems = [
    {
      id: 1,
      img: summerImg,
      title: "SUMMER SALE",
      desc: "DON'T COMPROMISE ON STYLE! GET FLAT 30% OFF FOR NEW ARRIVALS.",
      bg: "255,195,49,0.4",
    },
    {
      id: 2,
      img: autumnImg,
      title: "POPULAR SALES",
      desc: "DON'T COMPROMISE ON STYLE! GET FLAT 50% OFF FOR NEW ARRIVALS.",
      bg: "191,229,251,1",
    },
    {
      id: 3,
      img: loungewearImg,
      title: "LOUNGEWEAR LOVE",
      desc: "DON'T COMPROMISE ON STYLE! GET FLAT 40% OFF FOR NEW ARRIVALS.",
      bg: "255, 255, 255, 0.9",
    },
  ];

  export const categories = [
    {
      id: 1,
      img: shirtImg,
      title: "SHIRT STYLE!",
    },
    {
      id: 2,
      img: loungeSexyImg,
      title: "LOUNGEWEAR LOVE",
    },
    {
      id: 3,
      img: lightJacketImg,
      title: "LIGHT JACKETS",
    },
  ];

  export const popularProducts = [
    {
      id: '1',
      img: OrangeDress,
      name: 'Red sun - Kalaja Style - Dress',
      price: '90',
    },
    {
      id: '2',
      img: CasualJeans,
      name: 'Casual Jeans - Lutheran Fashion - Jeans',
      price: '120',
    },
    {
      id: '3',
      img: PurpleDress,
      name: 'Purple fragment - Kalaja Style - Dress',
      price: '150',
    },
    {
      id: '4',
      img: BlueDress,
      name: 'Blue Skyrie - Punika Style - Dress',
      price: '70',
    },
    {
      id: '5',
      img:"https://images.ctfassets.net/5gvckmvm9289/3BlDoZxSSjqAvv1jBJP7TH/65f9a95484117730ace42abf64e89572/Noissue-x-Creatsy-Tote-Bag-Mockup-Bundle-_4_-2.png",
      name: 'Blue Skyrie - Punika Style - Dress',
      price: '250',
    },
    {
      id: '6',
      img: GreenDress,
      name: 'Greenity - Mokoko Style - Dress',
      price: '170',
    },
    {
      id: '7',
      img: FlowerDress,
      name: 'Flowerly - Boréas Style - Dress',
      price: '125',
    },
    {
      id: '8',
      img:"https://www.pngarts.com/files/3/Women-Jacket-PNG-High-Quality-Image.png",
      name: 'Sasha\'s Jacket - Artheline Style - Dress',
      price: '79',
    },
    {
      id: '9',
      img:"https://www.pngarts.com/files/2/Cape-Coat-With-Hood-PNG-Image-Background.png",
      name: 'Sasha\'s Jacket - Artheline Style - Dress',
      price: '85',
    },
    {
      id: '10',
      img:"https://www.pngarts.com/files/2/Cape-Coat-With-Hood-PNG-Transparent-Image.png",
      name: 'Sasha\'s Jacket - Artheline Style - Dress',
      price: '115',
    },
    {
      id: '11',
      img:"https://www.pngarts.com/files/2/Cape-Coat-With-Hood-Transparent-Images.png",
      name: 'Sasha\'s Jacket - Artheline Style - Dress',
      price: '117',
    },
    {
      id: '12',
      img:"https://www.pngarts.com/files/3/Women-T-Shirt-PNG-Image-With-Transparent-Background.png",
      name: 'Sasha\'s Jacket - Artheline Style - Dress',
      price: '145',
    },
    {
      id: '13',
      img:"https://www.pngarts.com/files/1/T-Shirt-With-A-Heart-Transparent-Background-PNG.png",
      name: 'Sasha\'s Jacket - Artheline Style - Dress',
      price: '190',
    },
    {
      id: '14',
      img:"https://www.pngarts.com/files/12/Bikini-PNG-Download-Image.png",
      name: 'Sasha\'s Jacket - Artheline Style - Dress',
      price: '195',
    },
    {
      id: '15',
      img:"https://www.pngarts.com/files/1/T-Shirt-With-A-Heart-Free-PNG-Image.png",
      name: 'Sasha\'s Jacket - Artheline Style - Dress',
      price: '50',
    },
  ]

  export const subCategories = [
    {
      id: 1,
      img: SakuraCutie,
      title: "NEWEST FASHION !",
    },
    {
      id: 1,
      img: AsianModel,
      title: "CASUAL STYLE !",
    },
  
  ]
  