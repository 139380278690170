import React from 'react';
import Badge from '@material-ui/core/badge';
import { ShoppingCart } from "@material-ui/icons";
import { Search } from "@material-ui/icons";
import styled from 'styled-components';
import {Link, NavLink} from 'react-router-dom';
import { mobile } from '../responsive';
import { useState, useEffect } from 'react';


const Navbar = ({cart}) => {
  const [mobileState, setMobileState] = useState(false);
  const toggleClass = () => {
    setMobileState(!mobileState);
  };

  const [cartState, setCartState] = useState(cart.length);
  useEffect(() => {  
        setCartState(cart.length)
    })
  
  return (
    <Nav>
        <MobileButton onClick={toggleClass} className={`${mobileState ? 'active' : ''}`}>
                    <span className={`${mobileState ? 'active' : ''}`}></span>
                    <span className={`${mobileState ? 'active' : ''}`}></span>
                    <span className={`${mobileState ? 'active' : ''}`}></span>
        </MobileButton>
        <Wrapper className={`${mobileState ? 'active' : 'inactive'}`}>
            <Left>
                <Language>EN</Language>
                <SearchContainer>
                    <Input />
                    <Search style={{color:"gray", fontSize:"16px"}} />
                </SearchContainer>
            </Left>
            <Center>
                <Logo><Link to="/">SHOPIKAMI.</Link></Logo>
            </Center>
            <Right>
                <RightItem>
                    <Link to="/Register">REGISTER</Link>
                </RightItem>
                <RightItem>
                    <Link to="/Login">SIGN IN</Link>
                </RightItem>
                <RightItem>
                    <Link to="/Cart">
                        <Badge badgeContent={cartState} color="primary" overlap="rectangular">
                         <ShoppingCart />
                        </Badge>
                    </Link>
                </RightItem>
            </Right>
        </Wrapper>        
    </Nav>
  )
}

export default Navbar


const MobileButton = styled.button`
    display: none;

    @media screen  and (max-width: 780px){
          display: flex;
          margin: 10px;
          flex-direction: column;
          align-items: center;
          justify-content: space-evenly;
          outline: none;
          border: 1px teal solid;
          position: relative;
          align-self: center;
          height: 60px;
          width: 60px;
          background-color: #fafafa;
          z-index: 999999;
             & > span {
                content: '';
                display: block;
                position: relative;
                width: 80%;
                height: 2px;
                border-bottom: 2px teal solid;
                animation: rollback 1s;
                animation-fill-mode: forwards;
             }
             &.active > span:nth-child(1){
                position: absolute;
                align-self: center;
                animation: rotateCustom1 1s;
                animation-fill-mode: forwards;     
            }
            &.active>span:nth-child(3) {
                position: absolute;
                align-self: center;
                animation: rotateCustom2 1s;
                animation-fill-mode: forwards;  
            }
            &.active>span:nth-child(2){
                animation: rotateOut 1s;
                animation-fill-mode: forwards;
            }
    }

`


const Nav = styled.div`
    display: flex;
    position: sticky;
    width: 100%;
    top: 0;
    height: 60px;
    z-index: 99999;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    background-color: #fafafa;

    @media screen  and (max-width: 780px){
         display: flex;
         flex-direction: column;
         height: auto;
         width: 100%;
         z-index: 99999;
         box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }
`;

const Wrapper = styled.div`
    @media screen  and (min-width: 780px){
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;
        padding: 10px 20px;
    }
    @media screen  and (max-width: 780px){
          height: auto;
          width: 100%;        
          animation: fadeInDown 1s;
          animation-fill-mode: forwards;
          background: #fafafa;
          height: 0;
          display: none;
        &.active{
            margin: 20px 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-evenly;
            text-align: center;
            animation: fadeInDown 1s;
            animation-fill-mode: forwards;
            height: auto;
        }
       
    }
`;

const Left = styled.div`
    display: flex;
    align-items: center;
    flex: 1;
`;

        const Language = styled.span`
            font-size: 14px;
            cursor: pointer;
        `
        const SearchContainer = styled.div`
            display: flex;
            border: 0.25px solid lightgray;
            margin-left: 25px;
            padding: 5px;
        `;

        const Input = styled.input`
            border: none;
            &:focus{
                border: none;
                outline: none;                
            }
        `




const Center = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
`;

        const Logo = styled.h1`
                font-weight: bold;
        `
const Right = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex: 1;    
`;

        const RightItem = styled.div`
            font-size: 14px;
            margin-left: 25px;
            cursor: pointer;
        `;

