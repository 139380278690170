import React from 'react'
import styled from 'styled-components'
import CategoryItem from './CategoryItem';
import { categories, subCategories } from '../../data/data'
import SubCategories from './SubCategories'

const Categories = () => {
  return (
    <>
    <Container>
        {
          categories.map((item, index) => (
              <CategoryItem item={item} key={index} />
          ))
        }
    </Container>
    <SubContainer>
          {
            subCategories.map((item, index) => (
              <SubCategories item={item} key={index} />
            ))
          }
    </SubContainer>
    </>
  )
}

const Container = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 20px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    @media screen  and (max-width: 780px){
                flex-direction: column;
    }
`;

const SubContainer = styled.div`
    display: flex;
    justify-content: space-evenly;
    padding: 20px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    @media screen  and (max-width: 780px){
                flex-direction: column;
    }
`;

export default Categories